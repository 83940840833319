import accesedition from "@content/advanced/images/acces-edition-etude.png";
import ajoutcondtionnav from "@content/tutorial/images/ajouter-navigation-conditionnelle.png";
import conditions from "@content/tutorial/images/conditions-dactivation.png";
import icone from "@content/advanced/images/icone-navigation-conditionnelle.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatConditionNav() {
  return (
    <PageLayout title="Navigation conditionnelle" roles={["admin"]}>
      <p>
        Lorsque vous créez un eCRF, vous pouvez choisir de n'afficher certaines
        questions qu'à condition qu'une réponse précise ait été donnée à une
        question précédente (
        <em>
          Exemple : "Sexe du patient : H / F", si la réponse est "F" alors
          débloquer la question "La patiente est-elle enceinte ?"{" "}
        </em>
        ). Cette fonctionnalité est la navigation conditionnelle.
      </p>
      <Section
        id="accéder-à-la-navigation-conditionnelle"
        title="Accéder à la navigation conditionnelle"
      >
        <p>
          La navigation conditionnelle s'applique sur les{" "}
          <a href="/fr/advanced/models">
            <b>modèles de formulaires</b>
          </a>
          , il faut donc passer par le menu <b>Conception</b> puis l'onglet{" "}
          <b>Édition de l'étude</b>.
        </p>
        <img
          src={accesedition}
          alt="Onglet Édition de l'étude"
          className="centered"
        />
      </Section>
      <Section
        id="appliquer-la-navigation-conditionnelle"
        title="Appliquer la navigation conditionnelle"
      >
        <p>
          Pour l'appliquer à une variable, glissez votre curseur dessus et
          cliquez sur l'icône <b>Modifier la condition d'activation</b>.
        </p>
        <img
          src={ajoutcondtionnav}
          alt="Modifier les conditions d'activation"
          className="centered"
        />
        <p>
          Dans la fenêtre qui s'ouvre, cliquez sur les flèches à côté de{" "}
          <em>Aucune variable sélectionnée</em>, puis choisissez la variable qui
          activera la variable actuelle. Cliquez ensuite sur le bouton{" "}
          <b>+ Condition </b> et définissez la condition de résultat qui
          activera la variable actuelle.
        </p>
        <p>
          Il est possible d'appliquer jusqu'à deux conditions pour les variables
          non booléennes
        </p>
        .
        <img
          src={conditions}
          alt="Conditions d'activation"
          className="centered"
        />
        <p>
          Les conditions possibles varient selon le type de variable choisie
          pour référence :
        </p>
        <div className="responsive-table">
          <table>
            <tr>
              <td>Type de variable</td>
              <td>Conditions d'activation possibles</td>
            </tr>
            <tr>
              <td>Mesure, Calcul</td>
              <td>
                Si le résultat est : inférieur (&lt;), inférieur ou égal (≤),
                égal (=), différent de (≠), supérieur (&gt;), supérieur ou égal
                (≥) à la condition.
              </td>
            </tr>
            <tr>
              <td>Date et heure</td>
              <td>
                Si le résultat est : inférieur (&lt;), inférieur ou égal (≤),
                supérieur (&gt;), supérieur ou égal (≥) à la condition.
              </td>
            </tr>
            <tr>
              <td>Booléen</td>
              <td>Si le résultat est "Vrai" ou "Faux".</td>
            </tr>
            <tr>
              <td>Choix unique</td>
              <td>Si le résultat correspond à un choix précis.</td>
            </tr>
            <tr>
              <td>Choix multiple</td>
              <td>
                Si le nombre de choix cochés est : inférieur (&lt;), inférieur
                ou égal (≤), égal (=), différent de (≠), supérieur (&gt;),
                supérieur ou égal (≥) à la condition.
              </td>
            </tr>
          </table>
        </div>
        <Note type="note">
          La navigation conditionnelle ne peut pas utiliser comme référence des
          variables de type <b>texte</b> ou <b>contenu statique</b>.
        </Note>
        <p>
          Appuyez sur <b>Valider</b> pour confirmer votre choix. Une petite
          icône verte s'affiche à côté de votre variable pour indiquer qu'elle
          s'affichera bien de manière conditionnelle.
        </p>
        <img
          src={icone}
          alt="Navigation conditionnelle active"
          className="centered"
        />
      </Section>
    </PageLayout>
  );
}
